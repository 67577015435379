import { Box, Button } from "@mui/material"

type Props = {
  buttonName: string;
  isButtonDisabled?: boolean;
}

const buttonCreateEventStyle = {
  fontFamily: 'Zain',
  fontSize: 20,
  background: '#1E2282',
  height: '4vh',
  ml: '12px',
  borderRadius: '8px',
  textTransform: 'uppercase',
  letterSpacing: 0.46,
  wordWrap: 'break-word',
  mr:4,

  '@media (max-width: 800px)': {
    fontSize: '10px',
    height: '3.5vh',
    padding: '6px 12px',
  }

}

const HeaderButtonCreateEvent: React.FC<Props> = (props) => {
  return <Box>
    <Button disabled={props.isButtonDisabled} form='form-create-ev' type="submit"
      size="small" variant="contained" sx={buttonCreateEventStyle}>
      {props.buttonName}
    </Button>
  </Box>
}

export default HeaderButtonCreateEvent