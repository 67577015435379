import { Box, Button, Card, CardContent, Grid, Typography } from "@mui/material";
import FormsHeaderName from "../../components/Forms/FormsHeaderName";
import Evnt from "../../model/Event/Evnt";
import { useTranslation } from "react-i18next";
import { DATE_TIME_FORMAT } from "../Events/EventCreationComps/DetailsTab/EventDetailsTabSection";
import dayjs from "dayjs";
import CloseIcon from '@mui/icons-material/Close';


type Props = {
  curEevent: Evnt;
  onCloseModal:() => void
}

const EventDetailModalView: React.FC<Props> = ({curEevent, onCloseModal}) => {
  const { t } = useTranslation();
  const NOT_SPECIFIED = t('evdetails.card.notspecified');

return <>
<form
style={{ display: "flex", flexDirection: 'column', justifyContent: 'center', marginBottom: '24px' }}
>
  <Box
    sx={{

      gap: '24px',
      flexDirection: "column",
      alignItems: "center",
      width: 'calc(100% - 16px)',
      maxWidth: '640px',
      mt: '1vh',
      ml: '24px',
      mr: '16px',
    }}
  >

</Box>
  <Box ml='15px'>
    <CardContent>
      <Grid container spacing={2}>
      <Button onClick={onCloseModal} disableRipple sx={{
                       ml: '400px',
                        '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0)',
                        },
                    }}
                        startIcon={<CloseIcon sx={{ color: 'rgba(0, 0, 0, 0.56)' }} fontSize="large" />}>
                    </Button>
      <Grid item xs={12}>
          <Typography sx={{ ...headersStyle, mb: 1 }}>{t('evdetails.calendar.modal.headername')}</Typography>
          <Typography sx={{ ...headersStyle, color: '#07090F' }}>{curEevent.nameOfEvent}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ ...headersStyle, mb: 1 }}>{t('evdetails.card.date.start')}</Typography>
          <Typography sx={{ ...headersStyle, color: '#07090F' }}>{curEevent.dateAndTimeStart == DATE_TIME_FORMAT || !curEevent.dateAndTimeStart || dayjs(curEevent.dateAndTimeStart).isValid() === false ? NOT_SPECIFIED : dayjs(curEevent.dateAndTimeStart).format('MM/DD/YYYY hh:mm a')}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ ...headersStyle, mb: 1 }}>{t('evdetails.card.date.end')}</Typography>
          <Typography sx={{ ...headersStyle, color: '#07090F' }}>{curEevent.dateAndTimeEnd == DATE_TIME_FORMAT || !curEevent.dateAndTimeEnd || dayjs(curEevent.dateAndTimeEnd).isValid() === false ? NOT_SPECIFIED : curEevent.eventType == 'National Holidays' || curEevent.eventType === "الأعياد الوطنية" ? dayjs(curEevent.dateAndTimeEnd).endOf('day').format('MM/DD/YYYY hh:mm a') : dayjs(curEevent.dateAndTimeEnd).format('MM/DD/YYYY hh:mm a')}</Typography>
        </Grid>
      </Grid>

      <Grid container spacing={2} marginTop={2}>
        <Grid item xs={6}>
          <Typography sx={{ ...headersStyle, mb: 1 }}>{t('evdetails.card.mainguest.name')}</Typography>
          <Typography sx={{ ...headersStyle, color: '#07090F' }}>{curEevent.mainGuest ? curEevent.mainGuest : NOT_SPECIFIED}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ ...headersStyle, mb: 1 }}>{t('evdetails.card.evtype')}</Typography>
          <Typography sx={{ ...headersStyle, color: '#07090F' }}>{curEevent.eventType ? curEevent.eventType : NOT_SPECIFIED}</Typography>
        </Grid>
      </Grid>


      <Grid container spacing={2} marginTop={2}>
        <Grid item xs={12}>
          <Typography sx={{ ...headersStyle, mb: 1 }}>{t('evdetails.card.location')}</Typography>
          <Typography sx={{ ...headersStyle, color: '#07090F' }}>{curEevent.location ? curEevent.location : NOT_SPECIFIED}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ ...headersStyle, mb: 1 }}>{t('evdetails.card.description')}</Typography>
          <Typography sx={{
             ...(curEevent.description!.length > 350
               ?
               {
                ...headersStyle, ...borderMassiveTextStyle, color: '#07090F', overflowY: 'auto', maxHeight: '100px'
               } :
               {
              ...headersStyle, color: '#07090F', overflowY: 'auto', maxHeight: '150px'
             } 
               )
           }}>{curEevent.description!.trim() ? curEevent.description : NOT_SPECIFIED}</Typography>
        </Grid>
        <Grid item xs={12}>
                  <Typography sx={{ ...headersStyle, mb: 1 }}>{t("events.table.column.name.createdby")}</Typography>
                  <Typography sx={{ ...headersStyle, color: '#07090F' }}>{curEevent.createdBy ? (curEevent.createdBy) : NOT_SPECIFIED}</Typography>

                </Grid>
      </Grid>
    </CardContent>
  </Box>
</form>
</>

}

const headersStyle = {
  color: 'rgba(0, 0, 0, 0.60)',
  fontSize: 20,
  fontFamily: 'Zain',
  fontWeight: '400',
  letterSpacing: 0.15,
  wordWrap: 'break-word'
}

const borderMassiveTextStyle = {
  border: '1px solid rgba(0, 0, 0, 0.23)',
  borderRadius: '4px',                     
  boxSizing: 'border-box',
}

export default EventDetailModalView;