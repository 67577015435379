import { Box, Card, Checkbox, Chip, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField, Theme, useTheme } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from "react";
import { eventsService } from "../../../../configCommon/service-config";
import { useSelectorAuth } from "../../../../redux/store";

type SearchProps = {
    handleSearch:(e: React.ChangeEvent<HTMLInputElement>) => void,
    searchParam:string,
    callbackFilterGuestsFn:(e: React.ChangeEvent<HTMLInputElement>) => void,
}

const SearchTool: React.FC<SearchProps> = ({handleSearch, searchParam, callbackFilterGuestsFn}) =>  {
    const { t } = useTranslation();
    const [allGuests, setAllMainGuests] = useState<any[]>([]);
    const curUser = useSelectorAuth();
    const theme = useTheme();
    const [guestsNames, setGuestsNames] = useState<string[]>([]);
  
  useEffect(() => {
    fetchData();
    }, [])
  
    const fetchData = async () => {
    try {
      const allMainGuests = await eventsService.getAllMainGuests();
      setAllMainGuests(allMainGuests);
      } catch (error) {
        console.error('Error fetching data:', error)  
      }
    }

    function getStyles(name: string, guestName: readonly string[], theme: Theme) {
      return {
        fontWeight:
        guestName.indexOf(name) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium,
      };
    }

    const handleFilterGuests = (event: any) => {
      const {
        target: { value },
      } = event;
    
      if (value.includes("all")) {
        if (guestsNames.length === allGuests.length) {
          setGuestsNames([]);
        } else {
          setGuestsNames(allGuests.map((guest) => guest.nameAr));
        }
      } else {
        setGuestsNames(value); 
      }
     callbackFilterGuestsFn(event);
    };

    return <Card sx={{ 
        position: 'absolute',
        height: '90px',
        width: '90%',
        backgroundColor: '#fff',
        border: 0,
        borderRadius: '4px 4px 0px 0px',
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.12),0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)',
        marginTop: "25px"
    }}>
    
   <Box display='flex' flexDirection='row' gap={2}>
        <TextField
        fullWidth
        onChange={handleSearch} 
        value={searchParam}
        label= {t('events.page.textfield.label.search')}
        placeholder={t('events.page.textfield.placeholder.searchby')}
        variant="outlined"
        size="small" 
        InputLabelProps={{
            sx: {
              fontFamily: 'Zain',
              fontSize: '1.2rem',
             }
          }}
          InputProps={{
            sx: {
              fontFamily: 'Zain',
              fontSize: '1.2rem',
              opacity: 1
            },
          }}
          sx={{
            '& .MuiInputBase-root': {
              minHeight: '24px',
            },
            width: '270px',
            marginTop: '16px',
            marginLeft: '16px',

          }}>
           </TextField>
{curUser?.pddUser !== '0' &&

<FormControl
sx={{
  m: 1,
  marginTop: '16px',
  marginRight: '16px',
  width: 500,
  maxWidth: 1000,
  '& .MuiInputLabel-root': { 
      padding: '0 5px', 
      backgroundColor: '#fff', 
      fontSize: 20 
    } 
}}
fullWidth
   >
        <InputLabel
        sx={{
          fontFamily: 'Zain',
          fontSize: 20,
          lineHeight: '15px',
        }}
        id="demo-multiple-chip-label">{t('events.page.formcontrol.inputlabel.mainguest')}</InputLabel>
        <Select
         sx={{
          fontSize: '0.4rem' 
        }}
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={guestsNames}
          onChange={handleFilterGuests}
          input={<OutlinedInput id="select-multiple-chip2" label="Guests" />}
          renderValue={(selected:any) => (
            <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.2, height: '40px'}}>
              {selected.map((value:any) => (
                <Chip size="small" key={value} label={value.slice(0,20)+'...'} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
<MenuItem value="all"
    sx={{
      fontFamily: "Zain",
      fontSize: 20,
      minHeight: '24px',
      height: '24px',
    }}
  >
      {t('events.page.formcontrol.menuitem.allguests')}
</MenuItem>
          
          {allGuests.map((item: any) => (
            <MenuItem
            sx={{
              fontFamily: "Zain",
              fontSize: 20,
              minHeight: '24px',
              height: '24px',
            }}
              key={item._id}
              value={item.nameAr}
              style={getStyles(item.nameAr, guestsNames, theme)}
            >
              {item.nameAr}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
}

</Box>
</Card>

}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


export default SearchTool;