import HHSheikhMohammedbinRashidAlMaktoumLogo from '../assets/HH/HHSheikhMohammedbinRashidAlMaktoum.jpg';
import HHSheikhHamdanbinMohammedbinRashidAlMaktoumLogo from '../assets/HH/HHSheikhHamdanbinMohammedbinRashidAlMaktoum.jpg';
import HHSheikhMaktoumbinMohammedbinRashidAlMaktoumLogo from '../assets/HH/HHSheikhMaktoumbinMohammedbinRashidAlMaktoum.jpg';
import HHSheikhMansoorbinMohammedbinRashidAlMaktoumLogo from '../assets/HH/HHSheikhMansoorbinMohammedbinRashidAlMaktoum.jpg';
import HHSheikhAhmedbinMohammedbinRashidAlMaktoumLogo from '../assets/HH/HHSheikhAhmedbinMohammedbinRashidAlMaktoum.jpg';

export const hhPhotosMap = new Map();
 hhPhotosMap.set("1", HHSheikhMohammedbinRashidAlMaktoumLogo)
            .set("2", HHSheikhHamdanbinMohammedbinRashidAlMaktoumLogo)
            .set("3", HHSheikhMaktoumbinMohammedbinRashidAlMaktoumLogo)
            .set("4", HHSheikhMansoorbinMohammedbinRashidAlMaktoumLogo)
            .set("5", HHSheikhAhmedbinMohammedbinRashidAlMaktoumLogo)
