import { Box, IconButton } from "@mui/material"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

type Props = {
    onClickFn(): void;
}

const ReturnButton: React.FC<Props> = (props) => {

return <IconButton  disableRipple
sx={{
      ml: '42px',
      mt: '25px',
      width: '30px',
      height: '30px',
'&:hover': {
       backgroundColor: 'rgba(0, 0, 0, 0.05)',
       borderRadius: '50%',
       
      },
     transition: 'background-color 0.2s ease, transform 0.2s ease'
   }}

onClick={props.onClickFn} 

>
<ArrowForwardIcon fontSize="medium"/>
</IconButton>


}

export default ReturnButton;