import { Box, Button, Typography } from "@mui/material";
import { CustomTabsProps } from "../model/TabsProps";
import Evnt from "../model/Event/Evnt";
import GuestsListTabSection from "../pages/Events/EventCreationComps/GuestsTab/CommonGuestsTabsSection";
import EventDetails from "../pages/Events/EventCreationComps/DetailsTab/EventDetailsTabSection";
import ContactsList from "../pages/Contacts/ConactsList/ContactsList";
import GroupsLists from "../pages/Groups/GroupsLists";
import ChoosingTemplateCard from "../pages/Events/Invitation/ChoosingTemplateCard";
import SchedulerMain from "../pages/Scheduler/SchedulerMain";
import FormBasicsEvent from "../pages/Events/EventActions/CreateEventBasic/FormBasicsEvent";
import UpcomingEvents from "../pages/Events/EventActions/EventsPages/UpcomingEventsTable";
import PastEvents from "../pages/Events/EventActions/EventsPages/PastEventsTable";
import ContactML from "../model/Contact/ContactML";
import EmptyGuestLis from "../pages/Events/EventCreationComps/GuestsTab/EmptyGuestList";
import Contacts from "../pages/Events/EventCreationComps/GuestsTab/Contacts";
import GuestsList from "../pages/Events/EventCreationComps/GuestsTab/GuestsList";
import Groups from "../pages/Events/EventCreationComps/GuestsTab/Groups";
import ContactsGroupML from "../model/ContactsGroups/ContactsGroupML";
import GoogleAutocomp from "../pages/Events/EventActions/CreateEventBasic/GoogleAutocomp";

export const eventsTabsConfig: CustomTabsProps = {
  basePath: 'events',
  tabNames: ['upcoming', 'past'],
  labels: ['events.tab.upcoming', 'events.tab.past'],
  components: [
    <UpcomingEvents />,
    <PastEvents />,
  ],
};

export const contactsTabsConfig: CustomTabsProps = {
  basePath: 'contacts',
  tabNames: ['all', 'groups'],
  labels: ["contact.contacts.tab", "contact.groups.tab"],
  components: [
    <ContactsList/>,
    <GroupsLists/>,
  ]
};


export const newEventTabConfig = {
  basePath: 'events/new',
  components: (redirectToDetailsEvent: any, showSaveButton: boolean, showCreateEvButton: boolean, ev: Evnt, setEvOb: React.Dispatch<React.SetStateAction<Evnt>>) =>
    <FormBasicsEvent
      redirectToDetailsEvent={redirectToDetailsEvent}
      evOb={ev}
      setEvOb={setEvOb}
      showSaveButton={showSaveButton}
      showCreateEvButton={showCreateEvButton}
    />
}

export const eventsDetailsTabsConfig = {
  basePath: 'events/details',
  tabNames: ['details',
    //'guests',
    //'invitation'
    ],
  labels: ["details.event.tab.details",
   // "details.event.tab.guests",
   // "details.event.tab.invitation"
   ],
  components: [
    <EventDetails />,
    // <GuestsListTabSection />,
    // <ChoosingTemplateCard />
  ],
};

export const guestsListTabConfig = (
  handleAddContactClick: (contact: ContactML) => void,
  handleRemoveContactClick: (contact: ContactML) => void,
  handleAddGroupClick: (group: ContactsGroupML) => void,
  redirectToContacts: () => void,
  guestList: ContactML[],
  contacts: ContactML[],
  groups: ContactsGroupML[]

) => ({
  labels: [`createguestslist.tab.guestlist(${guestList.length})`, `createguestslist.tab.contacts(${contacts.length})`, `createguestslist.tab.groups(${groups.length})`],
  components: [
    <Box>
      {guestList.length === 0 ? (
        <EmptyGuestLis redirectToContacts={redirectToContacts} />

      ) : (
        <GuestsList
          contacts={guestList}
          onRemoveContact={handleRemoveContactClick} />
      )
      }
    </Box>,

    <Contacts
      contacts={contacts}
      onAddContact={handleAddContactClick}
    />,
    <Groups
      groups={groups}
      onAddGroup={handleAddGroupClick}
    />,
  ],
});

export const schedulerTabsConfig = {
  basePath: 'scheduler',
  components: [<SchedulerMain />],
};


