import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { FormControl, InputAdornment, InputLabel, MenuItem, Modal, Select, TextField } from '@mui/material';
import FormsHeaderName from '../../../../components/Forms/FormsHeaderName'
import { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import Evnt from '../../../../model/Event/Evnt';
import CreateEvSpinner from './CreateEvSpinner';
import MainBackgroundContainer from '../../CommonComponentsUI/MainBackgroundContainer';
import { useDispatch } from 'react-redux';
import { eventActions } from '../../../../redux/slices/eventSlice';
import SaveDraftButton from '../../../../components/HeaderListsCommonComps/SaveDraftButton';
import { useSelectorAuth, useSelectorEvent } from '../../../../redux/store';
import EventDetails from '../../EventCreationComps/DetailsTab/EventDetailsTabSection';
import { eventsService } from '../../../../configCommon/service-config';
import { formatToISOWithDayjs } from '../../../../utils/dateFuncs';
import { useTranslation } from 'react-i18next';
import eventTypesConf from '../../../../configCommon/events-config.json'
import { useNavigate } from 'react-router-dom';
import GoogleAutocompleteWithMap from './GoogleAutocomp';
import { checkForRegex, reg } from '../../../../utils/checkFuncs';
import HeaderButtonCreateEvent from '../../../../components/HeaderListsCommonComps/HeaderButtonCreateEvent';
const { eventTypes } = eventTypesConf;

type Props = {
  redirectToDetailsEvent?(): void;
  evOb?: Evnt;
  setEvOb?: React.Dispatch<React.SetStateAction<Evnt>>;
  showSaveButton: boolean;
  showCreateEvButton: boolean;
}

const nowDate: string = dayjs().format('YYYY-MM-DD')
//const { mainGuests, eventTypes } = eventsConf;
const initialEvent: Evnt = { nameOfEvent: '', dateAndTimeStart: null, dateAndTimeEnd: null, eventType: '', mainGuest: '', location: '', description: '' }

const FormBasicsEvent: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [openLocationModal, setOpenLocationModal] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<string>('');
  const [evObj, setEvObj] = useState<Evnt>(initialEvent);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [allGuests, setAllMainGuests] = useState<any[]>([]);
  const [allEvTypes, setallEventTypes] = useState<any[]>([]);

  //const reg = new RegExp(/^[a-zA-Z0-9\s]*$/);

  const dispatch = useDispatch();
  const curEevent = useSelectorEvent();
  const curUser = useSelectorAuth();
  const navigate = useNavigate();

  const filtereGuests = curUser?.pddUser == '0' ? allGuests.slice(1, 2) : allGuests;

  let newEvId;

  useEffect(() => {
    fetchData();
  }, [])

  const fetchData = async () => {
    try {
      const allMainGuests: any = await eventsService.getAllMainGuests();
      if (allMainGuests.message) {
        navigate('/')
        return;
      }
      setAllMainGuests(allMainGuests);
      const allEventTypes = await eventsService.getAllEventTypes();
      setallEventTypes(allEventTypes);
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  useEffect(() => {
    const curPath = window.location.href;
    if ((curPath.split('/')[curPath.split('/').length - 1] === 'new')) {
      setEvObj(initialEvent)
      return;

    }
    if (curEevent) {
      setEvObj({
        ...curEevent,
        dateAndTimeStart: dayjs(curEevent.dateAndTimeStart),
        dateAndTimeEnd: dayjs(curEevent.dateAndTimeEnd)
      });
      setSelectedLocation(curEevent.location || '');
    }
  }, [curEevent]);

  const handleLocationSelected = (location: string) => {
    setSelectedLocation(location);
    setEvObj({
      ...evObj,
      location: location,
    })
    if (props.setEvOb) {
      props.setEvOb((prevEvent: any) => ({
        ...prevEvent,
        location: location,
      }));;
    };
  };

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setEvObj((prevEvent: any) => ({
      ...prevEvent,
      [name]: value,
    }));
    if (props.setEvOb) {
      props.setEvOb((prevEvent: any) => ({
        ...prevEvent,
        [name]: value,
      }));;
    };
  }

  const handleChangeDate = (name: 'dateAndTimeStart' | 'dateAndTimeEnd', value: any) => {
    
    setEvObj({ ...evObj, [name]: value });
    if (props.setEvOb) {
      props.setEvOb((prevEvent: any) => ({
        ...prevEvent,
        [name]: value,
      }));
    };
  }

  const getLocationFn = () => {
    setOpenLocationModal(true);
  }

  const onSubmitFn = async (event: any) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const inputedDateTimeStart: string = data.get("dateAndTimeStart") as string;
    const inputedDateTimeEnd: string = data.get("dateAndTimeEnd") as string;
    const formattedDateTimeStart = formatToISOWithDayjs(inputedDateTimeStart);
    let formattedDateTimeEnd = '';
    if (inputedDateTimeEnd) {
      formattedDateTimeEnd = formatToISOWithDayjs(inputedDateTimeEnd);
    }

    
    if (checkForRegex(evObj)) {

      const newEvent: Evnt = {
        ...evObj,
        location: selectedLocation,
        dateAndTimeStart: inputedDateTimeStart == 'DD/MM/YYYY hh:mm aa' ? '' : formattedDateTimeStart,
        dateAndTimeEnd: inputedDateTimeEnd == 'DD/MM/YYYY hh:mm aa' ? '' : formattedDateTimeEnd ? formattedDateTimeEnd : '',
      //  createdBy: curUser?._id
      }
      setShowSpinner(true);
      setTimeout(() => {
        setShowSpinner(false);
        props.redirectToDetailsEvent!();
      }, 3000);
      console.log('new ev' + newEvent);
  
      newEvId = await eventsService.addEvent(newEvent);
      console.log(newEvId);
  
      dispatch(eventActions.set({
        ...newEvent,
        _id: newEvId
      }));
      event.target.reset();
      onResetFn()

    } else {
      return
    }

  }


  async function saveEventDraftFn() {
    if (evObj.nameOfEvent) {
      setEvObj({
        ...evObj,
        dateAndTimeStart: dayjs(evObj.dateAndTimeStart).format('DD/MM/YYYY hh:mm a'),
        dateAndTimeEnd: dayjs(evObj.dateAndTimeEnd).format('DD/MM/YYYY hh:mm a'),
      })
      dispatch(eventActions.set(evObj))
      setShowDetails(true);
      const res = await eventsService.updateEvent(curEevent._id!, evObj);
    }
  }
  const closeModalFn = () => {
    setOpenLocationModal(false)
  }
  const onResetFn = () => {
    setEvObj(initialEvent)
  }
  
  return (
    <>
      {showSpinner
        ?
        <CreateEvSpinner />
        : !showDetails ?
          <MainBackgroundContainer>
            <Card sx={{ width: 'calc(100% - 16px)', maxWidth: '580px', maxHeight: '77vh',
              height: {xs: 'auto', md:'90%'}, 
              ml: '16px', marginTop: '0px', borderRadius: "16px", paddingLeft: '24px', paddingRight: '24px', paddingBottom: '24px' }}>
              <form id='form-create-ev'
                onSubmit={onSubmitFn}
                onReset={onResetFn}
                style={{ display: "flex", flexDirection: 'row', justifyContent: 'center' }}
              >
                <Box
                  sx={{
                    gap: '24px',
                    flexDirection: "column",
                    alignItems: "center",
                    width: 'calc(100% - 16px)',
                    maxWidth: '640px',
                    mt: '1vh',
                    ml: '16px',
                    mr: '16px',
                    mb: '24px'
                  }}
                >
                  <Box display='flex' flexDirection='row' gap={12}>

                    <FormsHeaderName headerName=
                      {t('newev.form.header.name')}
                    />
                    {props.showSaveButton && <SaveDraftButton
                      butName={t('newev.form.button.save')}
                      disabled={false}
                      handleClickFn={saveEventDraftFn} />}
                  </Box>
                  <Box display='flex' gap='24px' flexDirection='column'>
                    <TextField
                   
                      onChange={handleInputChange}
                      value={evObj.nameOfEvent}
                      name="nameOfEvent"
                      size="small"
                      type="text"
                      required
                      fullWidth
                      label={t('newev.form.textfield.label.evname')}
                      multiline
                      error={evObj.nameOfEvent.trim() == '' || !reg.test(evObj.nameOfEvent.trim())}
                      helperText={evObj.nameOfEvent.trim() == '' ?
                        t('newev.form.textfield.helptext.warnreqfield') : 
                        t('newev.form.textfield.helptext.chars')}
                      InputLabelProps={{
                        sx: {
                          fontFamily: 'Zain',
                          fontSize: '1.2rem',

                        }
                      }}
                      inputProps={{
                        maxlength: 255,
                        sx: {
                          fontFamily: 'Zain',
                          fontSize: '1.2rem',
                          opacity: 1
                        },
                      }}
                      sx={{
                        '& .MuiInputBase-root': {
                          minHeight: '24',
                        },
                      }}
                    />
                    <FormControl required
                      sx={{
                        minHeight: '24px'
                      }}
                      fullWidth
                    >
                      <InputLabel
                        sx={{
                          fontFamily: mainFont,
                          fontSize: 20,
                          lineHeight: '15px',
                        }}
                      >
                        {t('newev.form.input.label.mainguest')}
                      </InputLabel>
                      <Select
                        value={evObj.mainGuest}
                        onChange={handleInputChange}
                        sx={{
                          fontFamily: mainFont,
                          fontSize: 20,
                          minHeight: '44px',
                          height: '44px',
                          lineHeight: '44px',
                        }}
                        name="mainGuest"
                        label="Main guest"
                      >
                        {filtereGuests.map((n) => (
                          <MenuItem
                            sx={{
                              fontFamily: mainFont,
                              fontSize: 20,
                              minHeight: '24px',
                              height: '24px',
                            }}
                            value={n._id}
                            key={n._id}
                          >
                            {n.nameAr}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <TextField
                      onClick={getLocationFn}
                      name="location"
                      size="small"
                      type="color"
                      fullWidth
                      label={t('newev.form.location.label.search')}
                      multiline
                      
                      InputLabelProps={{
                        sx: {
                          fontFamily: 'Zain',
                          fontSize: '1.2rem',
                        },
                      }}
                      InputProps={{
                        
                        sx: {
                          fontFamily: 'Zain',
                          fontSize: '1.2rem',
                          height: '24px',
                          boxSizing: 'border-box',
                          padding: '0 14px',
                          alignItems: 'center',
                          display: 'flex',
                        },
                        startAdornment: (
                          <InputAdornment sx={{ fontFamily: 'Zain', fontSize: '1.2rem' }} position="start">
                            {selectedLocation ? (
                              <>{selectedLocation.length > 80 ? `${selectedLocation.substring(0, 80)}...` : selectedLocation}</>
                            ) : (
                              <><SearchIcon sx={{ marginRight: '5px' }} />
                                {t('newev.form.location.input.locadress')}
                              </>
                            )}
                          </InputAdornment>
                        ),
                        readOnly: true,
                      }}
                      sx={{
                        
                        '& .MuiInputBase-root': {
                          height: '44px',
                          alignItems: 'center',
                        },
                        '& .MuiFormControl-root': {
                          height: '24px',
                        },
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      marginTop: '15px',
                      marginBottom: '24px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      gap: '24px',
                      width: '100%',
                      flexWrap: 'wrap'
                    }}
                  >

                    <Box display='flex' gap='12px' flexDirection='row'>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DateTimePicker']}>
                          <DateTimePicker
                            disablePast
                            closeOnSelect={false}
                            label=
                            {t('newev.form.datepicker.label.startdate')}
                            value={evObj.dateAndTimeStart}
                            onChange={(val) => handleChangeDate('dateAndTimeStart', val)}
                            minDateTime={dayjs(nowDate)}
                            name='dateAndTimeStart'
                            slotProps={{
                              textField: { required: true, InputLabelProps: { shrink: true }
                             }, field: {
                              format: "DD/MM/YYYY hh:mm a"
                             }
                            }}
                            sx={{
                              width: '100%',
                              '& .MuiInputLabel-root': {
                                fontFamily: 'Zain',
                                fontSize: '1.2rem',
                              },
                              '& .MuiInputBase-input': {
                                fontFamily: 'Zain',
                                fontSize: '1.2rem',
                                boxSizing: 'border-box',
                                height: '44px'
                              },
                              '& .MuiFormControl-root': {
                                height: '44px',
                              },
                              '& .MuiInputBase-root': {
                                height: '44px',
                              }
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DateTimePicker']}>
                          <DateTimePicker
                            disabled={!evObj.dateAndTimeStart}
                            disablePast
                            closeOnSelect={false}
                            label=
                            {t('newev.form.datepicker.label.enddate')}
                            // value={evObj.eventType == 'National Holidays' ? dayjs(evObj.dateAndTimeEnd).endOf('day').format('MM/DD/YYYY hh:mm a') : evObj.dateAndTimeEnd}

                            value={
                              evObj.eventType === 'National Holidays' || 
                              evObj.eventType === "الأعياد الوطنية"
                                ? dayjs(evObj.dateAndTimeEnd).isValid()
                                  ? dayjs(evObj.dateAndTimeEnd).endOf('day')
                                  : dayjs().endOf('day')
                                : dayjs(evObj.dateAndTimeEnd).isValid()
                                  ? dayjs(evObj.dateAndTimeEnd)
                                  : null
                            }
                            //value={evObj.dateAndTimeEnd}
                            onChange={(val) => handleChangeDate('dateAndTimeEnd', val)}
                            minDateTime={dayjs(evObj.dateAndTimeStart).add(1, 'hour')}
                            name='dateAndTimeEnd'
                            slotProps={{
                               textField: { InputLabelProps: { shrink: true } },
                               field: {
                                format: "DD/MM/YYYY hh:mm a"
                               }
                               }}
                            sx={{
                              width: '100%',
                              '& .MuiInputLabel-root': {
                                fontFamily: 'Zain',
                                fontSize: '1.2rem',
                              },
                              '& .MuiInputBase-input': {
                                fontFamily: 'Zain',
                                fontSize: '1.2rem',
                                boxSizing: 'border-box',
                                height: '44px'
                              },
                              '& .MuiFormControl-root': {
                                height: '44px',
                              },
                              '& .MuiInputBase-root': {
                                height: '44px',
                              }
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Box>
                    <FormControl sx={{
                      minHeight: '24px',
                    }}
                      fullWidth>
                      <InputLabel sx={{
                        fontFamily: mainFont,
                        fontSize: 20,
                        lineHeight: '15px',
                      }}>
                        {t('newev.form.formcontrol.label.eventtypes')}
                      </InputLabel>
                      <Select
                        value={evObj.eventType}
                        onChange={handleInputChange}
                        sx={{
                          fontFamily: mainFont,
                          fontSize: 20,
                          height: '44px',
                          '.MuiSelect-select': {
                            height: '44px',
                            display: 'flex',
                            alignItems: 'center',
                            paddingTop: '0px',
                            paddingBottom: '0px',
                          }
                        }}
                        name="eventType"
                        label="Event Type"
                      >
                        <MenuItem sx={{
                          fontFamily: mainFont,
                          fontSize: 20,
                          minHeight: '24px',
                          height: '24px',
                        }}
                          value="none">
                          {t('newev.form.types.menuitem.none')}
                        </MenuItem>
                        {eventTypes.typesAr.map((t) =>
                          <MenuItem sx={{
                            fontFamily: mainFont,
                            fontSize: 20,
                            minHeight: '24px',
                            height: '24px',
                          }} value={t} key={t}>{t}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Box>
                  <TextField
                    value={evObj.description}
                    onChange={handleInputChange}
                    fullWidth
                    label=
                    {t('newev.form.descript.label.description')}
                    name="description"
                    variant="outlined"
                    error={!reg.test(evObj.description!.trim())}
                    helperText={evObj.description?.trim() == '' ? t('newev.form.descript.helpertext') : t('newev.form.textfield.helptext.chars')}
                    placeholder={t('newev.form.descript.placeholder.evdescription')}
                    multiline
                    minRows={3}
                    maxRows={4}
                    InputLabelProps={{
                      sx: {
                        fontFamily: 'Zain',
                        fontSize: '1.2rem'
                      }
                    }}
                    inputProps={{
                      maxlength: 512,
                      sx: {
                        fontFamily: 'Zain',
                        fontSize: '1.2rem',
                        opacity: 1
                      },
                    }}
                  />
<Box mt="10px" ml='380px'>

{props.showCreateEvButton &&  <HeaderButtonCreateEvent buttonName={t('newev.header.button.createevent')}/>}

</Box>
                </Box>
              </form>
            </Card>
            <Modal
              open={openLocationModal}
              onClose={(event, reason) => {
                if (reason !== "backdropClick") {
                  setOpenLocationModal(false);
                }
              }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={styleModal}>
                {openLocationModal && (
                  <GoogleAutocompleteWithMap onCloseModalFn={closeModalFn}
                    onLocationSelected={handleLocationSelected} />
                )}
              </Box>
            </Modal>
          </MainBackgroundContainer> : <EventDetails />
      }
    </>)
}

const mainFont = 'Zain';
const styleModal = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '480px',
  height: {xs: 'auto', md:'90%'}, 
  maxHeight: '700px', 
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: '16px',
  padding: '24px',
};

export default FormBasicsEvent;