import * as React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import Evnt from '../../../../model/Event/Evnt'
import { useCallback, useEffect, useMemo, useState } from 'react';
import { authService, eventsService } from '../../../../configCommon/service-config';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration'
import SingleTextCell from '../../../../components/CustomDataGrid/EventsRows/SingleTextCell';
import CustomDataGridTypeEvents from '../../../../components/CustomDataGrid/CustomDataGridTypeEvents';
import SearchTool from './SearchTool';
import MainBackgroundContainer from '../../CommonComponentsUI/MainBackgroundContainer';
import SingleRedirectActionCell from '../../../../components/CustomDataGrid/EventsRows/SingleRedirectActionCell';
import { useDispatch } from 'react-redux';
import { eventActions } from '../../../../redux/slices/eventSlice';
import { useNavigate } from 'react-router-dom';
import { hhPhotosMap } from '../../../../configCommon/hh-photos-config';
import SingleDateCell from '../../../../components/CustomDataGrid/EventsRows/SingleDateCell';
import User from '../../../../model/User';
import { useSelectorEvent } from '../../../../redux/store';
import SingleTextCellWithFunc from '../../../../components/CustomDataGrid/EventsRows/SingleTextCellWithFunc';
import AvatarHHCellWithFunc from '../../../../components/CustomDataGrid/EventsRows/AvatarHHCellWithFunc';
dayjs.extend(utc);
dayjs.extend(duration);
const DEFAULT_EVENTS_DURATION = Number(process.env.REACT_APP_CONFIGURE_DEFAULT_EVENTS_DURATION);
const lsKeyAuth = process.env.REACT_APP_LS_AUTH_ITEM;

type Props = {
    past: boolean;
}
const EventsTable: React.FC<Props> = ({ past }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const curEv = useSelectorEvent();
   
    const columns: GridColDef[] = [
        SingleTextCellWithFunc({ fId: 'eventName', fFlex: 0.6, columnName: t("events.table.column.name.eventname"), marginL: 1 }),
        SingleTextCellWithFunc({ fId: 'createdBy', fFlex: 0.3, columnName: t("events.table.column.name.createdby"),  marginL: 1 }),
        SingleDateCell({ fId: 'dateAndTimeStart', fFlex: 0.2, columnName: t("events.table.column.name.date"), marginL: 1 }),
        AvatarHHCellWithFunc({fId: 'host', fFlex: 0.6, columnName: t('table.page.column.mainguest'), marginL: 1}),
        
];

    const [events, setEvents] = useState<Evnt[] | null>(null);
    const [filteredEvents, setFilteredEvents] = useState<Evnt[] | null>(null);
    const [searchEvName, setSearchEvName] = useState<string>('');
    const [users, setUsers] = useState<User[] | null>(null);
    const [allGuests, setAllMainGuests] = useState<any[]>([]);
    const [guestsNames, setGuestsNames] = useState<string[]>([]);
    
    const navigate = useNavigate();
    const toEventDetails = (ev: Evnt, row: any) => {
       dispatch(eventActions.set(ev));
      window.location.href = (`/events/details/${ev._id}`);
};

const handleFilterGuest = (e: any) => {
    setGuestsNames(e.target.value);
 };

 const fetchData = async () => {
    try {
    const allUsers = await authService.getAllUsers();
    setUsers(allUsers);
    
    const allMainGuests = await eventsService.getAllMainGuests();
    const curU = localStorage.getItem(lsKeyAuth!);
    const curUObj = JSON.parse(curU!);
    if (curUObj.pddUser == '0') {
       const mg = allMainGuests.filter((_, i) => i == 1)
        setAllMainGuests(mg)
            
    } else {
        setAllMainGuests(allMainGuests); 
    }
     } catch (error) {
      console.error('Error fetching data:', error)  
    }
}

useEffect(() => {
    fetchData();
  }, []); 

    useEffect(() => {
        const fetchData = async () => {
            try {
                const allEvents : any = await eventsService.getAllEvents();
                if (allEvents.message) {
                    navigate('/')
                    return;
            }
            // setEvents(allEvents)
            // if (curUser?.pddUser == false) {
            //     const nonPddEvents = allEvents.filter((ev:any) => ev.mainGuest == allGuests[0]._id);
            //     setEvents(nonPddEvents);
            // } 


    const dateAndTimeNow = dayjs.utc();
                if (past) {
                // if (curUser?.pddUser == false) {
                // const nonPddEvents = events!.filter((ev:any) => ev.mainGuest == allGuests[1]._id);
                //  setEvents(nonPddEvents);
                // } 
                        const pastEvents = allEvents.filter((ev: Evnt) => {
                        const eventEndLocal = dayjs(ev.dateAndTimeEnd).local();
                        const isNationalHoliday = ev.eventType === 'National Holidays' || ev.eventType === "الأعياد الوطنية";

                        if (isNationalHoliday) {
                            const endOfDay = eventEndLocal.endOf('day');
                            return dateAndTimeNow.isAfter(endOfDay);
                        }
                        return (
                            dateAndTimeNow.isAfter(dayjs.utc(ev.dateAndTimeEnd))
                    )
                        // ((ev.dateAndTimeEnd ?
                        //         dateAndTimeNow.isAfter(dayjs.utc(ev.dateAndTimeEnd)) :
                        //         dateAndTimeNow.isAfter(dayjs(ev.dateAndTimeStart).add(DEFAULT_EVENTS_DURATION, 'hour'))));
                    });
                    setEvents(pastEvents);
                    setFilteredEvents(pastEvents);
                } else {
                    
                        // if (curUser?.pddUser == false) {
                        // const nonPddEvents = events!.filter((ev:any) => ev.mainGuest == allGuests[1]._id);
                        //  setEvents(nonPddEvents);
                        // } 
                    const upcomingEvents = allEvents.filter((ev: Evnt) => {
                        const isBeforeStart = dateAndTimeNow.isBefore(ev.dateAndTimeStart);
                            const isBeforeEnd = dateAndTimeNow.isBefore(dayjs(ev.dateAndTimeEnd));
                            const isTodaySameDay = ev.eventType === 'National Holidays' || ev.eventType === "الأعياد الوطنية" 
                            && dayjs(ev.dateAndTimeEnd).local().isSame(dayjs().local(), 'day');
                            return isBeforeStart || isBeforeEnd || isTodaySameDay;
                           // dateAndTimeNow.isBefore((ev.dateAndTimeStart)) ||
                            // dateAndTimeNow.isBefore(dayjs(ev.dateAndTimeStart).add(DEFAULT_EVENTS_DURATION, 'hour')) ||
                           //  dateAndTimeNow.isBefore(dayjs(ev.dateAndTimeEnd)) ||
                            // || 
                        //ev.eventType == 'National Holidays' && dayjs(ev.dateAndTimeEnd).local() == dayjs.locale() ? 
                    });
                    setEvents(upcomingEvents);
                    setFilteredEvents(upcomingEvents);
                }
            } catch (error) {
                throw error;
            }
        };

        fetchData();
    }, []);

    const filterEvents = useCallback(() => {
        if (events) {
            let filtered = events;
            if (searchEvName) {
                const lowerCaseSearch = searchEvName.toLowerCase();
                filtered = filtered.filter((ev) => {
                    const eventNameMatches = ev.nameOfEvent.toLowerCase().includes(lowerCaseSearch);
                    const formattedDate = dayjs(ev.dateAndTimeStart).format('DD MMMM, YY').toLowerCase();
                    const dateMatches = formattedDate.includes(lowerCaseSearch);
                    return eventNameMatches || dateMatches;
                });
            }
            if (guestsNames && guestsNames.length > 0 && !guestsNames.includes('all')) {
                const guests = allGuests.filter(item => guestsNames.includes(item.nameAr));
                filtered = filtered.filter((ev) =>
                    guests.some(g => ev.mainGuest?.includes(g._id))
                );
            }
            setFilteredEvents(filtered);
        }
    }, [events, searchEvName, guestsNames, allGuests]);

    useEffect(() => {
       filterEvents();
    }, [events, searchEvName, guestsNames, allGuests]);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchEvName(e.target.value);
    };


    const getGuestName = (event: Evnt) => {          
         const ev = events!.find((e) => e._id === event._id && e.mainGuest === event.mainGuest);
         const guest = allGuests.find((g) => g._id === ev?.mainGuest);
           if (ev && guest) {
           return guest.nameAr;
           } else {
           return ''
       }
   }

const getCreatorName = (creatorId: string | undefined) => {
    let name;
    if (creatorId) {
        const ev = events?.find(e => e.createdBy == creatorId);
        if (ev) {
            const user = users?.find(u => u!._id == ev?.createdBy);
            name = user?.fullnameAR ?  user?.fullnameAR : user?.fullnameEN;
            return name;
          }
          return '';
    }
} 

   const getAvatar = (mainGuestId: string|undefined) => {
    if (mainGuestId) {
        const guest = allGuests.find((g) => g._id === mainGuestId);
        if (guest) {
        const av = hhPhotosMap.get(guest.avatar);
        return av;
       } 
    } else {
        return undefined;
    }
   }
          
    const getRows =  () => {
        return filteredEvents?.map((ev) => {
            const hostName = getGuestName(ev);
            const ava = getAvatar(ev.mainGuest);
            if (hostName && ava) {

                return {
                    id: ev._id,
                    eventName: {
                        value: ev.nameOfEvent,
                        func: toEventDetails,   
                        model: ev              
                    },
                    createdBy: {
                        value: getCreatorName(ev.createdBy),
                        func: toEventDetails,  
                        model: ev   
                    },

                   dateAndTimeStart: dayjs.utc(ev.dateAndTimeStart).local().toDate(),
                    
                //   dateAndTimeStart: {
                //         value: dayjs.utc(ev.dateAndTimeStart).local().toDate(),
                //         func: toEventDetails,
                //         model: ev,
                //     },
                
                    host: {
                        mainGuest: hostName, 
                        avatar: ava,
                        func: toEventDetails,  
                        model: ev         
                    },  
                     
                    // ev.dateAndTimeStart
                    //     ? dayjs.utc(ev.dateAndTimeStart).local().format('DD MMMM, YY')
                    //     : '-',
                    guests: ev.guests?.length ?? '-',
                    status: ev.status ?? '-',
                   actions: { 'func': toEventDetails, 'model': ev }
                };
              }
            return null;
         }).filter((row) => row !== null);
    }


    return (<>
        <MainBackgroundContainer sx={{ position: 'relative' }}>
            <SearchTool handleSearch={handleSearchChange} searchParam={searchEvName} callbackFilterGuestsFn={handleFilterGuest}/>
            <CustomDataGridTypeEvents
                rows={getRows()}
                columns={columns}
            />
        </MainBackgroundContainer>
    </>
     )
}

export default EventsTable;

