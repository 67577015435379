import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import FormsHeaderName from "../../../../components/Forms/FormsHeaderName";
import EditIconButton from "../../../../components/Forms/EditIconButton";
import MainBackgroundContainer from "../../CommonComponentsUI/MainBackgroundContainer";
import { useSelectorEvent } from "../../../../redux/store";
import { useEffect, useState } from "react";
import FormBasicsEvent from "../../EventActions/CreateEventBasic/FormBasicsEvent";
import dayjs from "dayjs";
import { useTranslation } from 'react-i18next';
import { authService, eventsService } from "../../../../configCommon/service-config";
import Evnt from "../../../../model/Event/Evnt";

export const DATE_TIME_FORMAT = 'DD/MM/YYYY hh:mm a'


type Props = {
  e?: Evnt
}


const EventDetails: React.FC<Props> = ({e}) => {
  const { t } = useTranslation();
  const NOT_SPECIFIED = t('evdetails.card.notspecified');
  const [showForm, setShowForm] = useState(false);
  const [allGuests, setAllMainGuests] = useState<any[]>([]);
  const [allEvTypes, setallEventTypes] = useState<any[]>([]);
  const [allUsers, setAllUsers] = useState<any[]>([]);
  const curEevent = useSelectorEvent();

  
  useEffect(() => {
    fetchData();
    }, [])
  
    const fetchData = async () => {
   try {
      const allMainGuests = await eventsService.getAllMainGuests();
      setAllMainGuests(allMainGuests);
      const allEventTypes = await eventsService.getAllEventTypes();
      setallEventTypes(allEventTypes); 
      const allUsers = await authService.getAllUsers();
      setAllUsers(allUsers);

      } catch (error) {
        console.error('Error fetching data:', error)  
      }
        
    }

    const findName = (array: any[], id: string) => {
      const foundObject : any = array.find((item: any) => item._id === id);
     const name = foundObject ? foundObject.nameAr || foundObject.name : 'not found';
     return name;
    }

    const getCreatorName = (creatorId: string | undefined) => {
      let name;
      if (creatorId) {
          const user = allUsers.find(u => u._id == creatorId);
          if (user) {
              name = user?.fullnameAR ?  user?.fullnameAR : user?.fullnameEN;
              return name;
            }
      }
  } 
    
  function editFn() {
    setShowForm(true);
  }

  return !showForm ? (
    <MainBackgroundContainer>
      <Card sx={{ width: 'calc(100% - 16px)', maxWidth: '580px', maxHeight: '77vh', height: {xs: 'auto', md:'90%'}, ml: '16px', marginTop: '0px', borderRadius: "16px" }}>
        <form
        style={{ display: "flex", flexDirection: 'column', justifyContent: 'center', marginBottom: '24px' }}
        >
          <Box
            sx={{
             gap: '24px',
              flexDirection: "column",
              alignItems: "center",
              width: 'calc(100% - 16px)',
              maxWidth: '640px',
              mt: '1vh',
              ml: '24px',
              mr: '16px',
            }}
          >

            <Box display='flex' flexDirection='row' gap={15}>
              <FormsHeaderName headerName=
              {t('evdetails.card.header.name')}
              />
              <Box marginLeft='200px'>
                <EditIconButton editFn={editFn} />
              </Box>
            </Box>

          </Box>
          <Box ml='15px'>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography sx={{ ...headersStyle, mb: 1 }}>{t('evdetails.card.date.start')}</Typography>
                  <Typography sx={{ ...headersStyle, color: '#07090F' }}>{curEevent.dateAndTimeStart == DATE_TIME_FORMAT || !curEevent.dateAndTimeStart || dayjs(curEevent.dateAndTimeStart).isValid() === false ? NOT_SPECIFIED : dayjs(curEevent.dateAndTimeStart).format(DATE_TIME_FORMAT)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ ...headersStyle, mb: 1 }}>{t('evdetails.card.date.end')}</Typography>
                  <Typography sx={{ ...headersStyle, color: '#07090F' }}>{curEevent.dateAndTimeEnd == DATE_TIME_FORMAT || !curEevent.dateAndTimeEnd || dayjs(curEevent.dateAndTimeEnd).isValid() === false ? NOT_SPECIFIED : curEevent.eventType == 'National Holidays' || curEevent.eventType === "الأعياد الوطنية" ? dayjs(curEevent.dateAndTimeEnd).endOf('day').format(DATE_TIME_FORMAT) : dayjs(curEevent.dateAndTimeEnd).format(DATE_TIME_FORMAT)}</Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} marginTop={2}>
                <Grid item xs={6}>
                  <Typography sx={{ ...headersStyle, mb: 1 }}>{t('evdetails.card.mainguest.name')}</Typography>
                  <Typography sx={{ ...headersStyle, color: '#07090F' }}>{curEevent.mainGuest ? findName(allGuests, curEevent.mainGuest) : NOT_SPECIFIED}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ ...headersStyle, mb: 1 }}>{t('evdetails.card.evtype')}</Typography>
                  <Typography sx={{ ...headersStyle, color: '#07090F' }}>{curEevent.eventType ? curEevent.eventType : NOT_SPECIFIED}</Typography>
                </Grid>
              </Grid>


              <Grid container spacing={2} marginTop={2}>
                <Grid item xs={12}>
                  <Typography sx={{ ...headersStyle, mb: 1 }}>{t('evdetails.card.location')}</Typography>
                  <Typography sx={{ ...headersStyle, color: '#07090F' }}>{curEevent.location ? curEevent.location : NOT_SPECIFIED}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ ...headersStyle, mb: 1 }}>{t('evdetails.card.description')}</Typography>
                  <Typography sx={{
                     ...(curEevent.description!.length > 350
                       ?
                       {
                        ...headersStyle, ...borderMassiveTextStyle, color: '#07090F', overflowY: 'auto', maxHeight: '90px'
                       } :
                       {
                      ...headersStyle, color: '#07090F', overflowY: 'auto', maxHeight: '150px'
                     } 
                       )
                   }}>{curEevent.description!.trim() ? curEevent.description : NOT_SPECIFIED}
                   </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography sx={{ ...headersStyle, mb: 1 }}>{t("events.table.column.name.createdby")}</Typography>
                  <Typography sx={{ ...headersStyle, color: '#07090F' }}>{curEevent.createdBy ? getCreatorName(curEevent.createdBy) : NOT_SPECIFIED}</Typography>

                </Grid>
              </Grid>
            </CardContent>
          </Box>
        </form>
      </Card>
    </MainBackgroundContainer>
  ) : (
    <FormBasicsEvent
        showSaveButton={true}
        showCreateEvButton={false}
      />
  )
}

const borderMassiveTextStyle = {
  border: '1px solid rgba(0, 0, 0, 0.23)',
  borderRadius: '4px',                     
  boxSizing: 'border-box',
}

const headersStyle = {
  color: 'rgba(0, 0, 0, 0.60)',
  fontSize: 20,
  fontFamily: 'Zain',
  fontWeight: '400',
  letterSpacing: 0.15,
  wordWrap: 'break-word'
}


export default EventDetails;