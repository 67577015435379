import React, { useRef, useState, useCallback, useEffect } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { Box, Button, Checkbox, IconButton, InputAdornment, List, ListItem, Paper, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography } from '@mui/material';
import mapsConf from "../../../../configCommon/maps-config.json";
import { useSelectorAuth } from '../../../../redux/store';
import { authService, googleMapsService } from '../../../../configCommon/service-config';
import { DeleteOutlineRounded } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { AdvancedMarker } from '@vis.gl/react-google-maps';
const API_GOOGLE_MAPS_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string

type Props = {
  onCloseModalFn: () => void;
  onLocationSelected:(location: string) => void;
}

const mapContainerStyle = {
  width: '100%',
  height: '400px',
};

const defaultCenter = {
  lat: 25.276987, 
  lng: 55.296249,
};


const GoogleAutocompleteWithMap: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const {gMaps} = mapsConf;
  const libs: any = gMaps.libraries;
  const [location, setLocation] = useState<{ lat: number; lng: number } | null>(null);
  const [loc, setLoc] = useState<any>('');
  const inputRef = useRef<HTMLInputElement>(null);
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const [selectedAddress, setSelectedAddress] = useState<string | null>(null);
  const [addressList, setAddressList] = useState<string[]>([]);
  const [savedLocations, setSavedLocations] = useState<any[]>([]);
  const curUser = useSelectorAuth();
  const [coordinates, setCoordinates] = useState<any>();
  const [selectedLocation, setSelectedLocation] = useState();



  console.log(curUser);
  
   

  useEffect(() => {
    const updateLocationsWithCoordinates = async () => {
        const locationsWithCoordinates = await Promise.all(
            savedLocations.map(async (address) => {
                const coordinates = await googleMapsService.getCoordinates(address);
                return coordinates ? coordinates : null;
            })
        );
      setCoordinates(locationsWithCoordinates as { lat: number, lng: number }[]);
};

    if (savedLocations.length > 0) {
        updateLocationsWithCoordinates();
    }
}, [savedLocations]);



  useEffect(() => {
    const fetchActUserData = async () => {
        const user = await authService.getUserData(curUser!._id);
        const actLocs = user?.locations;
        setSavedLocations(actLocs!);
         }
    fetchActUserData()
}, [])


  const onLoad = () => {
    if (inputRef.current) {
       autocompleteRef.current = new google.maps.places.Autocomplete(inputRef.current, {
        types: ['establishment'],
         fields: ['geometry']
      });
      autocompleteRef.current.addListener('place_changed', async () => {
        const place = autocompleteRef.current?.getPlace();
         if (place?.geometry?.location) {
          const lat = place.geometry.location.lat();
          const lng = place.geometry.location.lng();
          setLocation({ lat, lng });
          const formAddress = await googleMapsService.getLocation(String(lat), String(lng));
          setSelectedAddress(formAddress);
        }
      });
    }
  };

  const handleCloseModal = async () => {
    await authService.updateLocations(curUser!._id, savedLocations);
    props.onCloseModalFn();
    }

  const handleSelectLocation = (index: any) => {
    setSelectedLocation(index);
    setLoc(savedLocations![index])
    
};
const handleSaveLocation = () => {
  if (selectedAddress) {
  setSavedLocations((prevLocations) => [...prevLocations, selectedAddress]);
      setLoc('');
inputRef.current!.value = ''
setSelectedAddress(null);
  }
}


const handleDeleteLocation = (index: any) => {
  console.log(index);
  
  setSavedLocations((prevLocations: any) => {
      const updatedLocations = prevLocations.filter((_: any, i: any) => i !== index);
      console.log(updatedLocations.length);
      if (updatedLocations.length === 0) {
          console.log('empty');
          
          setCoordinates([]);
      }

      return updatedLocations;
  });
};

const saveLocations = async () => {
  props.onLocationSelected(loc);
  await authService.updateLocations(curUser!._id, savedLocations)
  props.onCloseModalFn()
}

const handleInputChange = (event:any) => {
  setLoc(event.target.value);
};

const handleMapClick = async (event: any) => {
  const latLng = event.latLng;
  
  if (latLng) {
    const lat = latLng.lat();
    const lng = latLng.lng();
    const address = await googleMapsService.getLocation(lat, lng);
    console.log(address);
    inputRef.current!.value = address
    setLoc(address);
    setSelectedAddress(address)
  }
};

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
    }}>

<Box display='flex' flexDirection='row' mb='-20px'>
        <Typography sx={styleMapHeader}>
            {t('locationform.header.name.choose')}
                </Typography>
                    <Button onClick={handleCloseModal} disableRipple sx={{
                        ml: '45px',
                        '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0)',
                        },
                    }}
                        startIcon={<CloseIcon sx={{ color: 'rgba(0, 0, 0, 0.56)' }} fontSize="large" />}>
                    </Button>
                </Box>


      <LoadScript googleMapsApiKey={API_GOOGLE_MAPS_KEY} libraries={libs}>
        
        <div style={mapStyle}>
        <GoogleMap
  mapContainerStyle={mapContainerStyle}
  center={location || defaultCenter}
  zoom={location ? 15 : 10}
  onLoad={onLoad}
  onClick={handleMapClick}
  options={{
    disableDefaultUI: true,
   }}
>
  {Array.isArray(coordinates) && coordinates.length > 0 && coordinates.map((loc: any, index: any) => (
    <Marker
      key={index}
      position={{ lat: parseFloat(loc.lat), lng: parseFloat(loc.lng) }}
      icon='' 
    />
  ))}
  {coordinates && !Array.isArray(coordinates) && coordinates.lat && coordinates.lng && (
    <AdvancedMarker
      position={{ lat: parseFloat(coordinates.lat), lng: parseFloat(coordinates.lng) }}
    />
  )}

  {location && <Marker position={location} />}
</GoogleMap>
        </div>
        
         <TextField
         onChange={handleInputChange}
        inputRef={inputRef}
            placeholder=''
            name="location"
            size="small"
            type="text"
            fullWidth
            label={inputRef.current?.value ? '' : t('locationform.textfield.label.location')}
            multiline
            rows={1}
            // InputProps={{

            //     startAdornment: (
                  
            //         <InputAdornment sx={{fontFamily: 'Zain', fontSize: '10'}} position="start">
            //             {loc ? (
            //             <>{loc.length > 70 ? `${loc.substring(0, 70)}...` : loc}</>
            //             ) : (
            //                 <><SearchIcon sx={{ marginRight: '5px' }} />
            //                 {t('locationform.textfield.input.locaddress')}
            //             </>
            //             )}
            //     </InputAdornment>
            //     ), 
            //     //readOnly: false
            // }} 
            />

<Box sx={{ display: 'flex' }}>
        <Button sx={confirmButStyle} onClick={handleSaveLocation} disabled={!selectedAddress}>
        {t('locationform.button.savelocation')}
        </Button>
        </Box>
      </LoadScript>

      <List>
        {addressList.map((address, index) => (
          <ListItem key={index}>{address}</ListItem>
        ))}
      </List>

      <TableContainer
             sx={{
                border: 'none',
                maxHeight: '170px',
                overflow: 'auto', 
                flexGrow: 6
            }}
                component={Paper}>
        <Table size='small' >
               <TableBody>
                    {savedLocations!.map((loc, index) => (
                        <TableRow key={index}>
                            <TableCell sx={{padding: '0.5px'}}>
                                <Checkbox
                                    sx={{
                                        color: '#2E7D32', 
                                        '&.Mui-checked': {
                                            color: '#2E7D32',
                                        },
                                        '& .MuiSvgIcon-root': {
                                            fontSize: 18, 
                                        }
                                    }}
                                    
                                    checked={selectedLocation === index}
                                    onChange={() => handleSelectLocation(index)}
                                />
                            </TableCell>
                            <TableCell sx={{fontFamily: 'Zain', fontSize: '20'}}>
                                {loc}
                            </TableCell>
                            <TableCell sx={{padding: 0}}>
                                <IconButton size='small' onClick={() => handleDeleteLocation(index)}>
                                    <DeleteOutlineRounded />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
           
        </TableContainer>

        <Box sx={{ textAlign: 'center' }}>
        <Button size="medium" variant="contained" 
                        disabled={selectedLocation == null || savedLocations?.length === 0}
                        sx={styleButton}
                        onClick={saveLocations}
                    >
                        {t('maps.modal.button.setloc')}
                    </Button>      
                    </Box> 
  </Box>
  );
};


const styleButton = {
  width: '100%',
  fontFamily: 'Zain',
  marginTop: '10px',
  fontSize: 20,
  height: '30px',
  borderRadius: '8px',
  textTransform: 'uppercase',
  letterSpacing: 0.46,
  backgroundColor: '#1E2282',
  color: 'white',
}

const styleMapHeader = {
  color: 'var(--text-primary, rgba(0, 0, 0, 0.87))',
  fontFamily: 'Zain',
  fontSize: '40px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '110%',
  letterSpacing: '0.25px',
  textAlign: 'left',
  width: '100%'
}

const confirmButStyle = {
  color: '#2E7D32',
  fontSize: '20',
  fontFamily: 'Zain',
  fontWeight: '400',
  letterSpacing: 0.15,
  wordWrap: 'break-word'
}

const mapStyle = {
  display: 'flex',
  flexDirecrion: 'column',
  alignItems: 'center',
  width: '482px',
  height: '35vh',
  borderRadius: '10px',
  overflow: 'hidden',
  marginTop: '24px',
  marginBottom: '24px'

}

export default GoogleAutocompleteWithMap;