/**
 * @desc `DateCell` is a functional component used to define a custom cell in row for a data grid. 
 * This cell renders a a `date` of the redered cell  
 *  
 * @param {ComponentProps} props - The properties passed to the component.
 * @property {string} fId - The unique field identifier for the column.
 * @property {number} fFlex - The flex value that controls the width of the column in the data grid.
 * @property {GridColType} [fType='date'] - Optional. The type of the grid column, defaulting to 'date'.
 * @property {string} [columnName] - Optional. The name to be displayed in the column's header.
 * @property {number} [marginL] - Optional. Left margin applied to the header.
 * @property {number} [marginR] - Optional. Right margin applied to the header.
 * 
 * @returns {GridColDef} - A grid column definition object containing the configuration for the `name` column.
 */

import { GridColDef, GridColType } from '@mui/x-data-grid';
import CustomDataGridHeader from '../Headers/CustomDataGridHeader';
import dayjs from 'dayjs';

interface ComponentProps {
    fId: string,
    fFlex: number,
    fType?: GridColType,
    columnName?: string,
    marginL?: number,
    marginR?: number
}

export default function SingleDateCell({ fId, fFlex, fType = 'date', columnName, marginL, marginR }: ComponentProps) {
    return {
        field: fId,
        flex: fFlex,
        type: fType, 
        renderHeader(_) { 
            return (<CustomDataGridHeader name={columnName} ml={marginL} mr={marginR} />); 
        },
       
        renderCell(params) {
          
            return (
                <>
                    <div style={{ fontFamily: 'Zain', fontSize: 18, marginLeft: 7 }}>
                        {params.value ? dayjs(params.value).format('DD.MM.YYYY') : ''}
                    </div>
                </>
            );
        },
    } as GridColDef;
}

