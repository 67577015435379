import { Box, Grid2 } from '@mui/material';
import ButtonExt, { VarianType } from '../components/SimpleUIComponents/Button/ButtonExt';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';

const InstallApp: React.FC = () => {
    const { t } = useTranslation();
    // const URL = process.env.REACT_APP_SECURE + "://" + process.env.REACT_APP_CONFIGURE_HOST;
    const ENV_FOLDER = process.env.REACT_APP_MOBILE_ENV;
    const URL = ENV_FOLDER === "staging" ? "https://marasimqa.dubai.gov.ae" : "https://marasim.dubai.gov.ae"

    const [searchParams, setSearchParams] = useSearchParams();
    let isKeyValid = searchParams.get("passKey") === "AdfZyf%4$1dDiFhHTCFGNMefwegr!fA1ffsf$5Gg1"

    return (
        <Grid2 container sx={{ justifyContent: 'space-around' }}>
            {isKeyValid &&
                <Box sx={{ display: 'grid' }} >
                    <Box component="img"
                        sx={{
                            height: 380,
                            width: 380,
                        }}
                        alt="ثَبَّتَ"
                        src={`/agenda/${ENV_FOLDER}/InstallQR.png`}
                    />
                    <ButtonExt
                        variant={VarianType.contained}
                        onClick={() => window.location.href = `itms-services://?action=download-manifest&url=${URL}/agenda/${ENV_FOLDER}/manifest.plist`}
                    >{"ثَبَّتَ".toUpperCase()}</ButtonExt>
                </Box>
            }
        </Grid2>
    );
};


export default InstallApp