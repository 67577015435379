import Evnt from "../model/Event/Evnt";

export let reg = /^[\p{L}\p{N}\u0600-\u06FF\s.,!?'"%$#@&*()\-+=:;]*$/u;

export const checkForRegex = (evnt:Evnt) => {
    reg = /^[\p{L}\p{N}\u0600-\u06FF\s.,!?'"%$#@&*()\-+=:;]*$/u;
    //const reg = new RegExp(/^[a-zA-Z0-9\s]*$/);
    const isNameValid = evnt.nameOfEvent.trim() !== '' && reg.test(evnt.nameOfEvent.trim());
    const isDescriptionValid = evnt.description ? reg.test(evnt.description.trim()) : true;
    return isNameValid && isDescriptionValid;
  }


  